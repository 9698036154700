import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    content: {
      '& h1': {
        color: theme.palette.primary.main,
        fontSize: '2rem',
      },
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: '2rem',
      },
      '& p': {
        fontSize: '1.5rem',
        lineHeight: '1.8rem',
        '& em': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        },
        '&:last-child': {
          textAlign: 'right',
          color: theme.palette.primary.main,
        }
      }
    }
  })
})

export default useStyles