import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import useStyles from '../styles/homeStyles'
import SEO from '../components/seo'

const IndexPage = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "home"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            background {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            seoDescription
            seoKeywords
            seoTitle
          }
          html
        }
      }
    }
  }
`)

  const content = query.allFile.nodes[0].childMarkdownRemark.html
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoDescription = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoDescription
  const seoKeywords = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoKeywords
  const seoTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoTitle
  return (
    <Layout isHome>
      <SEO title={seoTitle.length !== 0 ? seoTitle : pageTitle} description={seoDescription.length !== 0 ? seoDescription : null} keywords={seoKeywords.length !== 0 ? seoKeywords : null}/>
      <div dangerouslySetInnerHTML={{ __html: content }} className={classes.content} />
    </Layout>
  )
}
export default IndexPage
